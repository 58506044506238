import React, {Component} from "react";
import ProgressBar from "./ProgressBar";
import {IState as UploadingPageIState} from "./UploadPage"
import {States} from "./states.enum";
import {Button} from "@material-ui/core";


interface IProps {
    files:File[],
    access_token:string
    changeHigherState:(functie:(oldState:UploadingPageIState)=>Partial<UploadingPageIState>)=> void
    uploadMapID:string
}

interface IState {
    doneArray:number[]
    amountOfBytes:number
    percentage:number,
    uploadIDs:string[],
    cancelQuestion:boolean
}

class UploadingPage extends Component<IProps,IState>{
    private updater: any;
    private askCloseFunction:((event:BeforeUnloadEvent)=>void)
    private closeFunction:((event:Event)=>void)
    constructor(props:IProps){
        super(props)
        this.state={
            doneArray:[],
            amountOfBytes:0,
            percentage:0,
            uploadIDs:[],
            cancelQuestion:false
        }
        this.askCloseFunction=(event:BeforeUnloadEvent)=>{
            event.returnValue='Wil je echt de website sluiten?'
        }
        this.closeFunction=(event)=>{
            this.cancelUpload()
        }
    }

    componentDidMount(): void {
        window.addEventListener("beforeunload",   this.askCloseFunction)
        window.addEventListener("unload",  this.closeFunction)
        this.setState( {amountOfBytes:this.props.files.reduce((previousValue, currentValue)=> {return previousValue+currentValue.size},0),doneArray:new Array(this.props.files.length)})
        this.props.files.forEach((value, index) => {
            this.upload(value,index)
        })
    }
    componentWillUnmount(): void {
        window.removeEventListener('unload',this.closeFunction)
        window.removeEventListener("beforeunload", this.askCloseFunction)
    }

    upload = (file:File,index:number)=> {
        var data=JSON.stringify({"name":file.name,"parents":[this.props.uploadMapID]})

        fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable", {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Length': data.length.toString(),
                'Content-Type': "application/json; charset=UTF-8",
                'X-Upload-Content-Type': file.type,
                'X-Upload-Content-Length': file.size.toString(),
                'Authorization': "Bearer " + this.props.access_token
            },
            body:data
        }).then((value:any) => {

            var location=value.headers.get('location')
            this.setState(oldState=>{
                oldState.uploadIDs.push(location)
                return {uploadIDs:oldState.uploadIDs}
            })

            var interval=setInterval( ()=>{
                fetch(location,{
                    method: "PUT",
                    headers:{
                        "Content-Range":"bytes */"+file.size
                    }
                }).then((response)=>{
                    if(response.status===200||response.status===201||response.status===400||response.status===499){
                        clearInterval(interval)
                        if(response.status!==499){
                            this.setState(oldState=>{
                                oldState.doneArray[index]=file.size
                                return {doneArray:oldState.doneArray}
                            },this.updatePercentage)
                        }
                    }
                    try{
                        var Range=response.headers.get("range")
                        var amountOfBytes=parseInt(Range.split("-")[1])
                        this.setState(oldState=>{
                            oldState.doneArray[index]=amountOfBytes
                            return {doneArray:oldState.doneArray}
                        },this.updatePercentage)
                    }catch (e) {

                    }
                })

            },1000)

            fetch(location, {
                method: "PUT",
                headers:{
                    'Content-Length':file.size.toString(),
                    'Authorization': "Bearer " + this.props.access_token
                },
                body:file
            }).then((async (value1:any) => {
               if(value1.status!==503){
               const json=await value1.json()
               }
            }))

        })

    }



    updatePercentage=()=>{
        var done=this.state.doneArray.reduce((previousValue, currentValue) => previousValue+currentValue,0)
        this.setState({percentage:done/this.state.amountOfBytes}, ()  =>
        {
        if(this.state.percentage>=1){
            this.props.changeHigherState(oldState => {
                return {status:States.ready}
            })
        }
        })
    }

    cancelUpload=()=>{
        this.state.uploadIDs.forEach((value,index,array) => {

            fetch(value,{
                method:"DELETE",
                headers:{
                   'Content-Length': '0'
                }
            }).then(value1 => {
                if(index===array.length-1){
                    this.props.changeHigherState(oldState => {
                        return {status:States.inputMedia}
                    })
                }
            })
        })

    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="centerContent">
                <ProgressBar percentage={this.state.percentage}/>
                {this.state.cancelQuestion || <Button onClick={()=> this.setState({cancelQuestion:true}) }>Cancel</Button>}
                {this.state.cancelQuestion &&
                <div>
                    <Button variant={"contained"} color="primary" onClick={()=>this.setState({cancelQuestion:false})}>No</Button>
                    <Button variant={"contained"} color="primary" onClick={this.cancelUpload}>Yes</Button>
                </div>}
            </div>
        )
    }

}
export default UploadingPage