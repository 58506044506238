import React, {Component} from "react";
import InputMedia from "./InputMedia";
import {States} from "./states.enum";
import UploadingPage from "./UploadingPage";
import {Button} from "@material-ui/core";
import SelectSubject from "./selectSubject";
import FileUploadReady from "./FileUploadReady";

interface IProps {
    apiKey:string,
    access_token:string
    startMapId?:string
    useSelectSubject?:boolean
}

export interface IState {
    percentLoaded:number,
    status:States,
    files:File[],
    startMapID:string,
    uploadMapID:string,
    date:string,
    subject:string
    useSelectSubject:boolean
}

class UploadPage extends Component<IProps,IState>{

    constructor(props:IProps){
        super(props)
        this.state={
            percentLoaded:0.1,
            status:States.inputMedia,
            files:[],
            startMapID:"1qHCZm6FBz8LSxYVIJbyu4PJgvwS1FyMx",
            uploadMapID:"",
            date:(new Date()).toISOString().split('T')[0],
            subject:"",
            useSelectSubject:true
        }
    }

    changeState=(functie:(oldState:IState)=>Partial<IState>)=>{
        this.setState<never>(oldState=>{
            return functie(oldState)
        })
    }

    componentDidMount(): void {
        if(this.props.startMapId!==undefined){
            this.setState({startMapID:this.props.startMapId,useSelectSubject:this.props.useSelectSubject})
            if(!this.props.useSelectSubject){
                this.setState({uploadMapID:this.props.startMapId})
            }
        }

    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="fileVak" >
            {
                this.state.status===States.inputMedia && <InputMedia  files={this.state.files} nextRoute={this.state.useSelectSubject?States.selectSubject :States.uploading} changeFunction={this.changeState} />
            }
            {
                this.state.status===States.selectSubject && <SelectSubject date={this.state.date} subject={this.state.subject} access_token={this.props.access_token} apiKey={this.props.apiKey} changeHigherState={this.changeState} startMapID={this.state.startMapID} uploadMapID={this.state.uploadMapID}/>
            }
            {
                this.state.status===States.uploading&& <UploadingPage access_token={this.props.access_token} uploadMapID={this.state.uploadMapID} changeHigherState={this.changeState} files={this.state.files} />
            }
            {
                this.state.status===States.ready&& <FileUploadReady changeHigherState={this.changeState}/>
            }

            </div>
        )
    }
}
export default UploadPage
/*
      <div className="centerContent">
                <Button variant={"contained"} color="primary" onClick={event => {this.props.gapi.auth2.getAuthInstance().signOut()}}>Log Uit</Button>
            </div>
 */