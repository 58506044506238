import React, {Component} from "react";
import {BrowserRouter, HashRouter, Route, Switch} from "react-router-dom";
import UploadPage from "./UploadPage/UploadPage";
import GetFolders from "./UploadPage/GetFolders";
import UploadingPage from "./UploadPage/UploadingPage";

interface IProps {
    access_token:string
    apiKey:string
}

class SelectRoutes extends Component<IProps>{
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
        <HashRouter >
            <Switch>
                <Route path={"/:useSelectSubject/:id"} render={(props)=><UploadPage access_token={this.props.access_token} apiKey={this.props.apiKey} startMapId={props.match.params.id}  useSelectSubject={props.match.params.useSelectSubject==="1"} />}/>
                <Route path={"/"} render={()=><UploadPage access_token={this.props.access_token} apiKey={this.props.apiKey}/>}/>
            </Switch>
        </HashRouter>)
    }

}
export default SelectRoutes