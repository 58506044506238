import React, {Component} from "react";
import {ReactComponent as Close} from "../Media/close.svg"
import movie from "../Media/File Type Icons/movie.svg"
import  photo from "../Media/File Type Icons/photo.svg"
import  file from "../Media/File Type Icons/file.svg"
import {ReactComponent as Add} from "../Media/add.svg"
import {IState as UploadingPageIState, IState as IStateUploadPage} from "./UploadPage";
import {States} from "./states.enum";
import Button  from "@material-ui/core/Button";


interface IProps {
    changeFunction:(functie:(oldState:UploadingPageIState)=>Partial<UploadingPageIState>)=> void,
    files:File[]
    nextRoute:States
}

interface IState {
    formFile:string,
    dragin:boolean,
    percentLoaded:number
}

class InputMedia extends Component<IProps,IState>{
    public upload :React.RefObject<HTMLInputElement> = React.createRef()

    constructor(props:IProps){
        super(props)
        this.state={
            formFile:"",
            dragin:false,
            percentLoaded:0.1
        }
    }



    typeIcon(type:string):string{
        var iconName:string=file

        if(/^image\/.*/.test(type)){
            iconName=photo
        }

        if(/^video\/.*/.test(type)){
            iconName=movie
        }
        return iconName
    }



    addFile=(files:FileList)=>{
        this.props.changeFunction(
            (oldState:IStateUploadPage)=> {
                for (let i = 0; i < files.length; i++) {
                    oldState.files.push(files[i])
                }
                return oldState
            }
        )

    }

    //https://stackoverflow.com/questions/37300933/allow-typescript-compiler-to-call-setstate-on-only-one-react-state-property
    handleInputChange=(event:React.FormEvent<HTMLInputElement>)=> {
        const target = event.currentTarget;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(target.type==="file"){

            this.addFile(target.files)
            value=""
        }


        //https://stackoverflow.com/questions/37300933/allow-typescript-compiler-to-call-setstate-on-only-one-react-state-property
        this.setState<never>({[name]:value})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div  style={{backgroundColor:this.state.dragin && 'rgb(134,175,255)'}}
             onDrop={(event:React.DragEvent<HTMLDivElement>) =>{
                event.preventDefault()
                this.setState({dragin:false})
                this.addFile(event.dataTransfer.files)

            }}
            onDragOver={(event:React.DragEvent<HTMLDivElement>)=>{
                event.preventDefault()
                this.setState({dragin:true})
            }}
            onDragLeave={event => {
                this.setState({dragin:false})
            }}>
                <div className="fileItemList">
                    {
                        this.props.files.map((value,index) => {
                            return (
                                <div className="row fileItem">
                                    <img className="icon fileIcon" src={this.typeIcon(value.type)} alt="fileTypeIcon"/>
                                    <p className="noMarginHor" >{value.name}</p>
                                    <Close className="icon closeIcon" onClick={(event:React.MouseEvent) => {
                                        this.props.changeFunction((oldState:IStateUploadPage) =>{
                                            const newFiles= oldState.files.filter((value1, index1) => {
                                                return index1!==index
                                            })
                                            return {files:newFiles}
                                        })
                                }} />
                            </div>
                        )
                        })
                    }
                </div>
                <div className="invisable">
                    <input ref={this.upload} onChange={this.handleInputChange} type="file" multiple={true} name="formFile" value={this.state.formFile}/>
                </div>


                <div className="row centerContent" style={{cursor: "pointer"}}
                     onClick={(event => {
                    this.upload.current.click()
                })}>

                <div className="row centerContent">
                        <Add style={{fill:"rgb(40,152,255)"}} width={50} height={50}/>
                        <p>Voeg bestand toe</p>
                    </div>
                </div>
                {this.props.files.length!==0 &&
                <div className="row centerContent">
                    <a style={{cursor:"pointer",textDecoration:"underline"}} onClick={event => (this.props.changeFunction(oldState => {return {files:[]}}))}>Maak leeg</a>
                    <Button variant='contained' color="primary" className="mdc-button" onClick={()=>this.props.changeFunction((oldState:IStateUploadPage)=>{
                        return {status:this.props.nextRoute}
                    })}>Upload</Button>
                    </div>}

            </div>
        )
    }

}
export default InputMedia