import React, {Component} from "react";
import {IState as UploadingPageIState} from "./UploadPage";
import {Button} from "@material-ui/core";
import {States} from "./states.enum";
import {FileTypes} from "./fileTypes";


interface IProps {
    access_token:string
    changeHigherState:(functie:(oldState:UploadingPageIState)=>Partial<UploadingPageIState>)=> void,
    startMapID:string,
    apiKey:string,
    uploadMapID:string,
    date:string,
    subject:string
}

interface IState {
    suggestions:string[],
    loading:boolean
}

interface info {
    name:string
}

class SelectSubject extends Component<IProps,IState>{

    constructor(props:IProps){
        super(props)
        this.state={
            suggestions:[],
            loading:false
        }
    }

    componentDidMount=async ()=>{
        this.updateSuggestions(new Date())

    }


//https://www.googleapis.com/drive/v2/files/1qHCZm6FBz8LSxYVIJbyu4PJgvwS1FyMx/children?maxResults=25&q=mimeType%20=%20application%252Fvnd.google-apps.folder%20and%20name%20=%20%2729%20oktober%202019%27&key=AIzaSyCGABkG8qcxqzjfGHedA7AMckPsYzWlMXQ
    getChilderen= async (id:string,type: FileTypes|any,fields?: (keyof  DriveApiChildrenResponse['files'][0])[]  |"*",extraQuery?:string,log?:boolean) : Promise<(Partial<DriveApiChildrenResponse>)>=>{
        console.log(extraQuery)
        var fieldsQueary
        if(fields){
            fieldsQueary="&fields="
            if(fields==="*"){
                fieldsQueary=fields
            }else{
                fieldsQueary+=`files(${fields.join(",")})`
            }
        }else {
            fieldsQueary=""
        }
        var query=`mimeType = '${type}' and parents = '${id}' and trashed = false `
        if(extraQuery){
            query+=" and "+extraQuery
        }
        console.log(query)
        query=encodeURI(query)
        fieldsQueary=encodeURI(fieldsQueary)
        console.log("here")
        const fetchLocation=`https://www.googleapis.com/drive/v3/files?q=${query}${fieldsQueary}&key=`+this.props.apiKey
        console.log(fetchLocation)
        var lijst= await fetch(fetchLocation,
                {
                    method:"GET",
                    headers:{
                        Authorization: 'Bearer '+this.props.access_token,
                        Accept: 'application/json'
                    }
                })
        const lijstJSON=await lijst.json()
        if(log){
            console.log(lijstJSON)
        }

        return lijstJSON
    }


    getDateMapID=async (date:Date)=>{
        const dateString=`${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`
        var children=await this.getChilderen(this.props.startMapID,FileTypes.folder,["id","name"],`name = '${dateString}'`)
        return children
    }


    //https://www.googleapis.com/drive/v2/files/1qHCZm6FBz8LSxYVIJbyu4PJgvwS1FyMx/children?maxResults=25&q=mimeType%20%3D%20'application%2Fvnd.google-apps.folder'%20and%20name%20=%20'29%20oktober%202019'&key=AIzaSyCGABkG8qcxqzjfGHedA7AMckPsYzWlMXQ

    updateSuggestions=async (date:Date)=>{
        const uitkomst=(await this.getDateMapID(date)).files
        var optieLijst:string[]=[]
        if(uitkomst.length !== 0){
            const opties=await this.getChilderen(uitkomst[0].id,FileTypes.folder)
            optieLijst=opties.files.map(value => value.name)
        }
        this.setState({suggestions:optieLijst})
    }

    handleInputChange=(event:React.ChangeEvent<HTMLInputElement>)=> {
        const target = event.currentTarget;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(target.type==="date" && typeof value=== "string"){
            //console.log(value)
            var date=new Date(value)
            this.updateSuggestions(date)
        }

        this.props.changeHigherState(oldState => {
            return {[name]: value}
        })
    }

    createMap=async (name:string,parent:string):Promise<string>=>{
        const body=JSON.stringify({
            "parents": [
            parent
        ],
            "mimeType": "application/vnd.google-apps.folder",
            "name": name
    })
        const response=await fetch("https://www.googleapis.com/drive/v3/files?key="+this.props.apiKey,{
            method:"POST",
            headers:{
                Authorization: 'Bearer '+this.props.access_token,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        body:body
        })
        const JSONbody=await response.json()
        return JSONbody.id
    }

    setUploadMap=async ()=>{
        var uploadMapID=this.props.startMapID
        const dateMap=await this.getDateMapID(new Date(this.props.date))
        if(dateMap.files.length===0){
            var date=new Date(this.props.date)
            const dateString=`${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`
            const mapID=await this.createMap(dateString,uploadMapID)
            uploadMapID=await  this.createMap(this.props.subject,mapID)
        }else{
            const subjectDateMap=await this.getChilderen(dateMap.files[0].id,FileTypes.folder,["id"],` name = '${this.props.subject}'`,true)
            if(subjectDateMap.files.length===0){
                uploadMapID=await this.createMap(this.props.subject,dateMap.files[0].id)
            }else{
                uploadMapID=subjectDateMap.files[0].id
            }
        }
        await this.props.changeHigherState(oldState => {return{uploadMapID:uploadMapID}})
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="marginToElement">
                <div className="centerContent fullInput marginToElement">
                    <p>Selecteer een opnamedatum en onderwerp</p>
                    <label>Opnamedatum: <input type="date" placeholder={this.props.date} name="date" value={this.props.date} onChange={this.handleInputChange}/></label>
                    <label>Onderwerp: <input type="string" autoComplete="off" name="subject" value={this.props.subject} list="autoComplete" onChange={this.handleInputChange}/></label>

                    <datalist id="autoComplete">
                        {this.state.suggestions.map((value,index) => <option key={index} value={value}/>)}
                    </datalist>
                </div>
                { !this.state.loading?
                    <div className="row centerContent">
                        <Button variant='contained' color="primary"
                                onClick={() => this.props.changeHigherState(oldState => {
                                    return {status: States.inputMedia}
                                })}>Back</Button>
                        {this.props.subject!==""
                            ?
                            <Button variant='contained' color="primary" onClick={async event => {
                                this.setState({loading:true})
                                await this.setUploadMap()
                                this.props.changeHigherState(oldState => {
                                    return {status: States.uploading}
                                })
                            }}>Next</Button>
                            :
                            <p className="noMarginHor">Selecteer een Onderwerp</p>
                        }



                    </div>
                    :
                    <p className="center">Loading..</p>
                }
            </div>
        )
    }
}
export default SelectSubject