import React from "react";

interface IProp {
    percentage:number
}

function ProgressBar(props:IProp) {
    return(
        <div>
            <p>{Math.floor(props.percentage*100)}%</p>
            <svg height="170" width="170" className="spinner" shapeRendering="geometricPrecision" viewBox="0 0 170 170">
                <circle className="spinner__background" r="80" cx="85" cy="85" fill="transparent"
                        style={{strokeDasharray: 502.655, strokeDashoffset: 0, strokeWidth: 10, stroke: "rgb(232, 235, 237)"}}></circle>
                <circle className="spinner__foreground" r="80" cx="85" cy="85" fill="transparent"
                        style={{strokeDasharray: 502.655, strokeDashoffset:(1-props.percentage)*502.655, strokeWidth: 10, stroke: "rgb(64, 159, 255)",strokeLinecap:"round"}}></circle>
            </svg>
        </div>
    )
}
export default ProgressBar