import React from 'react';
import './App.css';


import LoadLibary from "./LoadLibary";
import Authenticatie from "./Authenticatie";

function App() {
  return (
      <Authenticatie/>

  );
}

export default App;
