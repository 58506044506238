import React, { Component} from "react";
import UploadPage from "./UploadPage/UploadPage";
import {ReactComponent as WatLogo} from './Media/WatLogo.svg'
import {Button} from "@material-ui/core";
import SelectRoutes from "./SelectRoutes";
const jwt=require('jsonwebtoken')

interface IState {
    signedIn:boolean,
    gapi?:typeof gapi,
    apiKey:string,
    access_token:string
}

class Authenticatie extends Component<{},IState>{

    constructor(props:{}){
        super(props)
        this.state={
            signedIn:false,
            apiKey:"AIzaSyCGABkG8qcxqzjfGHedA7AMckPsYzWlMXQ",
            access_token:null
        }
    }

    componentDidMount=async ()=> {
        const privateKey="-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDH+lNAjj/2dU/n\nqWtspR1quycMYVwYFYLPOsVbJD/5BtHe8zQJEGo9osEYFx/edKhLM28e6y5b9fDR\nZSVT13++xE7Jqf8dpCvlrN/wbOhVv7C8lEEqOyIk5KyajbytUdRpQkggIejSMyzp\nDYig97jchQ02WNmKz4Hl27rARt4IqFmSrZTU8wOfxXWDp0F+wo25Bb5zEhEYC0AN\nPExn2Yuew6TrflR8gaZxxIIFJvOSXibkFH3XWwB/wuulz4j5Io0H0sM3Im9T4Ex5\nL4hp6S4n7x69Cw5w3BIQamF9praWfbzzBuaagA3NL2PlxxG8O0mvM8dC0+7YkBKD\n/p2xNZTdAgMBAAECggEADCndePp+CP1jNhXPvyy+4JBQJHhii+N5EKZEPVIz4u+H\nge5cjqWVNLkK0i6L5XHGj8Zp3qFS8UyBEJd8oQAlWMLjgPAqYJDtC5wLhfn12std\nUmcZHJq6rbBMEF2JKailyR47OVseqHRs6aMFWbcfoygjoNfgKYmMHuFR12KoHDIX\ngbkNTCZmjgI4x3Hpba8WZ6PRRuXmZEY6yMTj8pstPCw3aT6o1HUPnJcaua1Zhu26\nuSL2z/5v120NCVPHBy8EZc8MOGgefP8I5Gyh3qKSSw0XqOMty5yyU5iKM97DOYZ2\nkdMGph/7SFl/AnC+crN1OeygQ/257bQkXsytpI89EQKBgQD/C4KyAbVN4FEcIwIq\nklImYG2AEv+hBnyZEkYktNGV7lDdCrqd60QUxJXwytuVMTahI7z8KH3fGUN3zcv5\nxCUFrZdvTylofinrSj2njhFRkuq2RkvKsdpqKeSsUz3Rmo9JhJYNb7vA04E/rzkG\nSZ2wMNcvVPFZj4/wgITwqYfAsQKBgQDIugbPCD4ZIqRJmcHJzerJZYVaHvDZBrZS\nJRAmiyh3MziTSJ75XZDb49HkfOcPDIsJ8Tvg/UcCS1LF+VMGnKfiltf113p0Tsh7\nIZXY4NL4LFAH0BrIlYlTfuDqAlJ5r1HwVp/0uWBtYrIFEfLk/8NJKTbmVdOwaCi7\nT/FrUR+B7QKBgA4hZiFUpOXmqrfDXMhdCP4lU4vIAa+7O0YD1C0Oc5o59PL6ODKt\nqTz5U1Fe++F9M4ikAUF7QNYOgUVu+oEhE+gHoR9CpImsi8gEefwBjAYX/LcSl/pn\n9jDxl0DQ79jgFlY9aU7fROi/DycnHyKxC3KKkjGGJEURBimOBU+MJQBRAoGAHLOU\n46efEYMFkMyejBeYmeGdVsyTzcSK72YDUC+XwqudTFoW+TsqynZh/dTqSRmXC/3l\nfP9aVvakSFClUY2258x/5tQ/2z+kpQ6hWZiUMg5MB0H3gnT3ZOwAhxV765BpG/+W\n8axMIHf7UoRFgf8cUmrFQdxF37SVFmuQ7rJz2eUCgYEAgxoOZ2Y5nHCCHFmQiOt7\nl+XJ7jq0VCeGTEsXJr4Ae7gUGf+e0HfwMQWI+y0NQn+T8KLCzN3rvKd2OXrVGmn7\n/1BY1zGVMK/L0TnsdbUJWgVSvnrPe4L8wARwTlXLixiBOGgoTEaKziHj2jT8r9so\nI20/sRvH50Az7hUbwQLFSEU=\n-----END PRIVATE KEY-----\n"
        var token=jwt.sign({aud:'https://oauth2.googleapis.com/token',scope:"https://www.googleapis.com/auth/drive ",iss:"wat-media@wat-upload-project.iam.gserviceaccount.com"},privateKey, {algorithm: 'RS256', expiresIn:"1h",header: {
                'kid':"e37c55c29001e2d97d9a0c44f5542bb4e5423a8a",
                'typ': 'JWT',
                'alg': 'RS256',
            }})
        var result = await fetch("https://oauth2.googleapis.com/token",{
            method:"POST",
            headers:{
                Host: 'www.googleapis.com',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body:"grant_type=urn%3Aietf%3Aparams%3Aoauth%3Agrant-type%3Ajwt-bearer&assertion="+token
        })
        var jsonResult= await result.json()
        this.setState({access_token:jsonResult.access_token})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div>
                {this.state.access_token!==null?
                    <SelectRoutes apiKey={this.state.apiKey} access_token={this.state.access_token} />
                    :
                    <p>login By google</p>
                }
            </div>
        )
    }


}
export default Authenticatie
/*
<div className="centerContent center column marginToElement">
                        <p> Welkom bij de Wat oploader. Log in met je google account</p>
                        <WatLogo width={150}/>
                        <Button variant={"contained"} color="primary" onClick={(event:React.MouseEvent)=>{this.state.gapi.auth2.getAuthInstance().signIn()}}>Login</Button>
                    </div>
*/