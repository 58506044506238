import React, {Component} from "react";
import {Button} from "@material-ui/core";
import {States} from "./states.enum";
import {IState as UploadingPageIState} from "./UploadPage";

interface IProps {
    changeHigherState:(functie:(oldState:UploadingPageIState)=>Partial<UploadingPageIState>)=> void
}

class FileUploadReady extends Component<IProps>{

    render(){
        return(
            <div className="centerContent ">
                <p>Uw media is succesvol ontvangen</p>
                <Button onClick={event => {
                    this.props.changeHigherState(oldState => {return {status:States.inputMedia,files:[],subject:"",date:(new Date()).toISOString().split('T')[0]}})
                }} className="center" variant={"contained"} color="primary">Nog een keer Uploaden</Button>
            </div>
        )
    }
}
export default FileUploadReady